<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="5">
          <CCardGroup>
            <CCard class="p-4 bg-card">
              <CCardBody>
                <CForm @submit.prevent="Login">
                  <h1>Login</h1>
                  <p class="text-muted">Iniciar sesión en su cuenta</p>

                  <CInput
                    placeholder="Username"
                    autocomplete="username email"
                    v-model="username"
                  >
                    <template
                      ><CIcon name="cil-user"
                    /></template>
                  </CInput>
            

                  
                  <CInput
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                    v-model="password"
                  >
                    <template
                      ><CIcon name="cil-lock-locked"
                    /></template>
                  </CInput>
                  

                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton color="primary" type="submit" class="px-4"
                        >Login</CButton
                      >
                    

                    </CCol>
                    
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>
<style>
.c-app{
   background: url('~@/assets/img/bg-login.jpg') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.bg-card{
  /* background-color: transparent !important; */
  opacity: 0.9;
}
</style>
<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require('json-encrypt')

import { mapState } from "vuex";


export default {
  name: "Login",
  data() {
    return {
      username: '',
      password: '',
    };
  },
  methods: {
    Login,
  },
  mounted() {
    
  },
  computed: {
    ...mapState(["url_base"]),
  },
};

//login
function Login() {

  let me = this;
  const email = this.username;
  const password = this.password;
  
  let url = this.url_base + "login";

  let data = {
    email: email,
    password: password,
  };


  axios({
    method: "POST",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  }).then(function (response) {

    
    if (response.data.status == 200) {
      
      let user = je.encrypt(JSON.stringify(response.data.result.user));
      let token = je.encrypt(response.data.result.user.api_token);
      let user_permissions = je.encrypt(JSON.stringify(response.data.result.userPermissions));

      window.localStorage.setItem(
        "user",user
      );

      window.localStorage.setItem(
        "user_permissions",
        user_permissions
      );

      
      me.$router.push({ name: "Home"})
      // var usuario = JSON.parse(JSON.parse(je.decrypt(window.localStorage.getItem("user"))));
 
      Swal.fire({
        icon: "success",
        title: "Bienvenido al sistema",
        showConfirmButton: false,
        timer: 1500,
      });
    
    }else{

      Swal.fire({
        icon: "error",
        title: "Datos incorrectos",
        showConfirmButton: false,
        timer: 1500,
      });

    }
  });

}
</script>
